@import 'node_modules/nhsuk-frontend/packages/core/all';

.filtersPanel {
    float: left;
    box-shadow: inset 0 0 0 1px #b1b4b6;
    margin-right: 40px;
    max-width: 385px;
    min-width: 260px;
    width: 20%;
}

.filtersHeader {
    background-color: $color_nhsuk-grey-3;
}

.filtersSelected {
    background-color: $color_nhsuk-grey-4;
    box-shadow: inset 0 0 0 1px $color_nhsuk-grey-3;
}

.filtersClearFilter {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.filters {
    box-shadow: inset 0 0 0 1px $color_nhsuk-grey-3;
    margin-top: -2px;
}
