@use 'node_modules/nhsuk-frontend/packages/core/all' as *;

.fileUpload {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;

    &.fileUploadError {
        border-left: 3px solid $color_nhsuk-red;
        padding-left: 15px;
    }
}

.fileUploadLabel {
    width: 100%;
    margin-bottom: 10px;
}

.validationError {
    color: $color_nhsuk-red;
    font-weight: bold;
    width: 100%;
}

.fileUploadInput {
    @include nhsuk-font(19);
    width: 50%;
    margin-left: -5px;
    padding: 5px;

    &::-webkit-file-upload-button {
        -webkit-appearance: button;
        color: inherit;
        font: inherit;
    }

    &:focus {
        @include nhsuk-focused-input();
    }

    &:focus-within {
        @include nhsuk-focused-input();
    }
}

.fileUploadImportControls {
    button:first-child {
        margin-right: 15px;
    }
}
