.select {
  width: 100%;
}

.createDalLinksButtons {

  button:first-child {
    margin-right: 15px;
  }
}
