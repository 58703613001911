.sortHeadButton {
  padding-right: 24px;
  position: relative;
}

.button {
  background-color: inherit;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  padding: 0;
}

.arrow {
  fill: #000 !important;
  position: absolute;
  right: 0;
  top: -2px;
}

.asc .arrow {
  top: 7px;
  transform: rotate(-90deg);
}

.desc .arrow {
  top: -8px;
  transform: rotate(90deg);
}

.none .arrow:nth-child(1) {
  top: 7px;
  transform: rotate(-90deg);
}

.none .arrow:nth-child(2) {
  top: -8px;
  transform: rotate(90deg);
}
