@import 'node_modules/nhsuk-frontend/packages/core/all';

.paging_link {
    border: 0;
    font-size: 1.1875rem;
    cursor: pointer;
    background-color: transparent;
    color: $color_nhsuk-blue;
    padding: 1px 8px;
    outline: none;
}

.paging_link:hover {
    color: $color_nhsuk-black;
}

.paging_link.disabled {
    color: #999;
}

.paging_link.current {
    color: $color_nhsuk-black;
}

.paging_link:first-child {
    padding-left: 0;
}
