@import 'node_modules/nhsuk-frontend/packages/core/all';

.Pagination,
.PaginationResults {
    position: relative;
    top: -24px;
}

.Pagination {
    float: left;
}

.PaginationResults {
    float: right;
}
