/* Taken from _width-container.scss govuk-width-container mixin */
@import 'node_modules/nhsuk-frontend/packages/core/all';

.appBreadcrumb {
    div {
        @include govuk-media-query($from: desktop) {
            margin: 0 $nhsuk-gutter;
        }
    }
}
