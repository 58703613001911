.inlineInputButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > div {
    margin-right: 15px;
    width: 59ex;
    font-size: 1.1875rem;

    input {
      text-transform: uppercase;
    }
  }

  > button {
    flex-shrink: 1;
    align-self: flex-start;
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.addUserButtons {

  button:first-child {
    margin-right: 15px;
  }
}
