@import 'node_modules/nhsuk-frontend/packages/core/all';

.PaginationResults {
    position: relative;
    top: -24px;
}

.highlight {
    margin: 0 4px;
    font-weight: 700;
}
